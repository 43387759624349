import React from "react";
import ServerStatus from "./ServerStatus";

import { subscribe, unsubscribe } from '../../../event/event';

export const SysMessage = ({chatMsg, initialStatus}) => {
    const [bShow, setbShow] = React.useState(true);
    const [bHiding, setbHiding] = React.useState(false);

    React.useEffect(() => {
      subscribe('showSysMsg', onShow);
      subscribe('hideSysMsg', onHide);

      onShow();
    }, []);

    const onShow = () => {
      setbShow(true);
      setbHiding(false);
    }

    const onHide = () => {
      setbHiding(true);
      hidePromise();
    }

    const hidePromise = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setbShow(false);
    };

    return (
      bShow ? 
      <div className={bHiding ? 'chat-content-server-status-hiding' : 'chat-content-server-status'}>
        <ServerStatus status = {initialStatus}/>
        <div className='loading-text'>{chatMsg}</div>
      </div> : <></>
    );
}