import React, { useEffect, useState } from "react";
import "../../../styles/components/chat/Content.css";
import Introduction from "./Introduction";
import PromptExamples from "./PromptExamples";
import ChatSkeleton from "../../skeleton/ChatSkeleton";
const ChatContent = ({
  containerRef,
  chatContents,
  isTermsAgree,
  setIsTermsAgree,
  isThinking,
  setChatText,
  scratchText
}) => {

  return (
    <div ref={containerRef} className="chat-content-container">
      <Introduction

        scratchText={scratchText}

      />
      <hr style={{ borderTop: '2px solid #000', margin: '20px 0' }} />

      {chatContents}

      {isThinking && <ChatSkeleton />}
    </div>
  );
};

export default ChatContent;
