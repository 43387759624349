import React from "react";

import "../../../styles/components/chat/Sidebar.css";
import "../../../styles/components/chat/History.css";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import SidebarSkeleton from "../../skeleton/SidebarSkeleton";
import { useSearchParams } from "react-router-dom";



const Sidebar = ({ container, setContainer, chatlistcontainerRef, chatLists, newChatClicked, isLoading }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  console.log(chatLists, "chat Lists")
  return (
    <> 
      
      <div className="chat-sidebar">
      {!searchParams.get('chatId') && !searchParams.get('fastSearch')?<div className="switch-container">
          <div className="btn-group">
            <Button className={container === "Chat" ? "btn-chat active" : "btn-chat"} onClick={() => setContainer("Chat")}>
              Chat
            </Button>
            <Button className={container === "Browse" ? "btn-browse active" : "btn-browse"} onClick={() => setContainer("Browse")}>
              Search
            </Button>
            <Button className={container === "ScratchPad" ? "btn-scratch active" : "btn-scratch"} onClick={() => setContainer("ScratchPad")}>
              Scratch
            </Button>
          </div>
          <Button className="btn-new-chat" onClick={() => window.location.href = '/news'}>
            <span>NEWS</span>
          </Button>
        </div>:""}
        {!searchParams.get('chatId') && !searchParams.get('fastSearch')?
        <div className="new-chat-container">
          <Button className="btn-new-chat" onClick={newChatClicked}>
            <Add />
            <span>Start New Chat</span>
          </Button>
        </div>:""}
        {!searchParams.get('chatId') ? <div ref={chatlistcontainerRef} className="chat-history-container">
          {isLoading ? <SidebarSkeleton /> : chatLists}
        </div>:""}
      </div> 
    </>
  );
};

export default Sidebar;
