import React, { useState, useEffect, useRef } from "react";
import Header from "../header/Header";
import "../../styles/layout/NewsPage.css";
import axios from "axios";
import { publish } from "../../event/event";
import { API_BASE_URL } from "../../utils/const";
import { toast } from "react-toastify";

const News = () => {
    const [news, setNews] = useState([]);
    const isFetchedRef = useRef(false); // To track if data is already fetched

    const getNews = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/ato_news/get_news`);
            const sortedNews = response?.data.sort((a, b) => new Date(b.Published_Date) - new Date(a.Published_Date));
            setNews(sortedNews);
        } catch (error) {
            toast.error("Error fetching data: " + error);
        } finally {
            publish("endLoading");
        }
    };

    useEffect(() => {
        if (!isFetchedRef.current) {
            getNews();
            isFetchedRef.current = true; // Mark as fetched
        }
    }, []);

    return (
        <>
            <Header />
            <div className="news-page">
                <div className="bg-news-banner">
                    <h6>WELCOME TO THE NEWS</h6>
                    <h4>NEWS SUMMARIES</h4>
                    <p>Check our blog for minute-by-minute updates on these breaking stories.</p>
                </div>
                <div className="flex-container-wrapper">
                    <div className="flex-container">
                        {news.map((item) => (
                            <div className="flex-item" key={item.id}>
                                <h4>{item.News_Title}</h4>
                                <p>{item.Summary}</p>
                                <h6>{item.Published_Date}</h6>
                                <a href={item.News_URL} target="_blank" rel="noopener noreferrer">Read More</a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default News;
