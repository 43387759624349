export const toastOption = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

//export const API_BASE_URL = "http://13.55.158.224:443/api";
export const API_BASE_URL = "https://api.taxgenii.lodgeit.net.au/api";

export const LODGEIT_URL = "https://lodgeit.net.au";

export const ROLE_USER = "user";
export const ROLE_SYSTEM = "system";
export const ROLE_KNOWLEDGE = "knowledge";
export const ROLE_ASSISTANT = "assistant";
