// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browse-sidebar {
  width: 340px;
  min-width: 340px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
}

.browse-sidebar-vertical-line {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-right: 1px solid #e7e7e0;
}

.browse-sidebar .switch-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 22px 20px 20px !important;
}
.new-chat-container{
  margin: 0 22px 20px 20px !important;
}

.btn-group button {
  width: 76px;
  height: 28px;
  border-radius: unset;
  box-sizing: border-box;
  background: white;
  color: #333;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  border: 1px solid #2895eb;
}

.btn-group .btn-chat {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.btn-group .btn-browse {
  /* border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; */
  border-radius: 0;
}

.btn-group .btn-scratch {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.btn-group button.active {
  background: #2895eb;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/browse/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,QAAQ;EACR,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,sCAAsC;AACxC;AACA;EACE,mCAAmC;AACrC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,sBAAsB;EACtB,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE;qCACmC;EACnC,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".browse-sidebar {\r\n  width: 340px;\r\n  min-width: 340px;\r\n  background-color: #fafafa;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.browse-sidebar-vertical-line {\r\n  position: absolute;\r\n  top: 0;\r\n  bottom: 0;\r\n  right: 0;\r\n  border-right: 1px solid #e7e7e0;\r\n}\r\n\r\n.browse-sidebar .switch-container {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  margin: 20px 22px 20px 20px !important;\r\n}\r\n.new-chat-container{\r\n  margin: 0 22px 20px 20px !important;\r\n}\r\n\r\n.btn-group button {\r\n  width: 76px;\r\n  height: 28px;\r\n  border-radius: unset;\r\n  box-sizing: border-box;\r\n  background: white;\r\n  color: #333;\r\n  font-size: 12px;\r\n  font-weight: 600;\r\n  line-height: normal;\r\n  border: 1px solid #2895eb;\r\n}\r\n\r\n.btn-group .btn-chat {\r\n  border-top-left-radius: 10px;\r\n  border-bottom-left-radius: 10px;\r\n}\r\n\r\n.btn-group .btn-browse {\r\n  /* border-top-right-radius: 10px;\r\n  border-bottom-right-radius: 10px; */\r\n  border-radius: 0;\r\n}\r\n\r\n.btn-group .btn-scratch {\r\n  border-top-right-radius: 10px;\r\n  border-bottom-right-radius: 10px;\r\n}\r\n\r\n.btn-group button.active {\r\n  background: #2895eb;\r\n  color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
