import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import TextareaAutosize from "react-textarea-autosize";

import { ReactComponent as ThumbsUp } from "../../../images/thumbs-up.svg";
import { ReactComponent as ThumbsDown } from "../../../images/thumbs-down.svg";
import "../../../styles/components/chat/ChatFeedback.css";
import { API_BASE_URL } from "../../../utils/const";

const ChatFeedBack = ({ promptId }) => {
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleFeedbackChange = (event) => setFeedback(event.target.value);

  const handleVoteUp = () => {
    axios
      .post(`${API_BASE_URL}/chat/set-vote`, {
        prompt_id: promptId,
        vote: "Up",
      })
      .then((response) => {
        if (response.data === "success") {
          toast.success("Thanks for your feedback!");
        }
      })
      .catch((error) => {
        toast.error("Error fetching data:" + error);
      });
  };

  const handleVoteDown = () => {
    axios
      .post(`${API_BASE_URL}/chat/set-vote`, {
        prompt_id: promptId,
        vote: "Down",
      })
      .then((response) => {
        if (response.data === "success") {
          toast.success("Thanks for your feedback!");
        }
      })
      .catch((error) => {
        toast.error("Error fetching data:" + error);
      });
  };

  const handleFeedbackSubmit = () => {
    if (feedback === "") {
      toast.warning("Please input the your feedback.");
      return;
    }

    axios
      .post(`${API_BASE_URL}/chat/set-feedback`, {
        prompt_id: promptId,
        feedback: feedback,
      })
      .then((response) => {
        if (response.data === "success") {
          toast.success("Thanks for your feedback!");
          setOpen(false);
          setFeedback("");
        }
      })
      .catch((error) => {
        toast.error("Error fetching data:" + error);
      });
  };

  return (
    <>
      <div className="chat-content-feedback-button-group">
        <button className="chat-content-vote-up-button" onClick={handleVoteUp}>
          <ThumbsUp />
        </button>
        <button
          className="chat-content-vote-down-button"
          onClick={handleVoteDown}
        >
          <ThumbsDown />
        </button>
        <button className="chat-content-feedback-button" onClick={handleOpen}>
          Feedback
        </button>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box className="chat-feedback-modal">
            <Typography className="chat-feedback-title">Feedback</Typography>
            <Box className="chat-feedback-additional">
              <TextareaAutosize
                rows={3}
                minRows={3}
                maxRows={9}
                className="chat-feedback-input"
                placeholder="How can the response be improved?"
                value={feedback}
                onChange={handleFeedbackChange}
              />
            </Box>
            <Box className="chat-feedback-footer">
              <button
                className="chat-content-feedback-submit-button"
                onClick={handleFeedbackSubmit}
              >
                SUBMIT
              </button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ChatFeedBack;
