export const chopString = (originalString, maxLength) => {
  return originalString.length > maxLength ? `${originalString.substring(0, maxLength)}...` : originalString;
};

export const getHierrachy = (hierrachy) => {
  var hierrachyArray = hierrachy.split(" > ");
  if (hierrachyArray[0] === "ATO general website") {
    hierrachyArray.shift();
  }
  hierrachyArray.shift();
  var s = hierrachyArray.join(" > ");
  if (hierrachy.endsWith(" > ") === true) {
    s = hierrachy.substring(0, hierrachy.length - 2);
  }
  return s;
};

export const getTitle = (hierrachy) => {
  var s = hierrachy;
  if (hierrachy.endsWith(" > ") === true) {
    s = hierrachy.substring(0, hierrachy.length - 2);
  }
  const i = s.lastIndexOf(" > ");
  return s.substring(i + 3);
};
