// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container {
  display: flex;
  width: 100%;
  background-color: #fcfcf9;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background: white;
}

.drawer-title {
  background: #2895eb;
  color: white;
  font-size: 11px;
  font-weight: 600;
  text-align: left;
  padding: 7px 25px 8px 25px;
  font-weight: 400;
}
.custom-drawer-wrapper{
  width: 50% !important;
  overflow: scroll;
  height: 514px !important;

}


.custom-drawer-wrapper::-webkit-scrollbar{
  -webkit-appearance: none;
  width: 7px;
}

.custom-drawer-wrapper::-webkit-scrollbar-thumb{
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
}






 .custom-drawer-wrapper .css-vqmohf-MuiButtonBase-root-MuiRadio-root{ padding: 7px !important;}

 






.custom-drawer{
  width: 100%;
  display:flex;
}
.custom-drawer .left-side{
  width: 50%;
}
.custom-drawer .right-side{
  width: 50%;
}



`, "",{"version":3,"sources":["webpack://./src/styles/layout/chat/ChatPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAO;EACP,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,0BAA0B;EAC1B,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,wBAAwB;;AAE1B;;;AAGA;EACE,wBAAwB;EACxB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,mCAAmC;AACrC;;;;;;;CAOC,qEAAqE,uBAAuB,CAAC;;;;;;;;;AAS9F;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ","sourcesContent":[".chat-container {\r\n  display: flex;\r\n  width: 100%;\r\n  background-color: #fcfcf9;\r\n}\r\n\r\n.main-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 1;\r\n  background: white;\r\n}\r\n\r\n.drawer-title {\r\n  background: #2895eb;\r\n  color: white;\r\n  font-size: 11px;\r\n  font-weight: 600;\r\n  text-align: left;\r\n  padding: 7px 25px 8px 25px;\r\n  font-weight: 400;\r\n}\r\n.custom-drawer-wrapper{\r\n  width: 50% !important;\r\n  overflow: scroll;\r\n  height: 514px !important;\r\n\r\n}\r\n\r\n\r\n.custom-drawer-wrapper::-webkit-scrollbar{\r\n  -webkit-appearance: none;\r\n  width: 7px;\r\n}\r\n\r\n.custom-drawer-wrapper::-webkit-scrollbar-thumb{\r\n  border-radius: 4px;\r\n  background-color: rgba(0, 0, 0, .5);\r\n}\r\n\r\n\r\n\r\n\r\n\r\n\r\n .custom-drawer-wrapper .css-vqmohf-MuiButtonBase-root-MuiRadio-root{ padding: 7px !important;}\r\n\r\n \r\n\r\n\r\n\r\n\r\n\r\n\r\n.custom-drawer{\r\n  width: 100%;\r\n  display:flex;\r\n}\r\n.custom-drawer .left-side{\r\n  width: 50%;\r\n}\r\n.custom-drawer .right-side{\r\n  width: 50%;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
