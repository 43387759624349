// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browse-container {
  display: flex;
  width: 100%;
  padding: 80px;
  overflow-y: auto;
  background-color: white;
}

.browse-container::-webkit-scrollbar {
  width: 6px;
}

.browse-container::-webkit-scrollbar-thumb {
  background-color: #a6a5a5;
  border-radius: 20px;
}

.browse-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.browse-container::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}

.browse-container .switch-container {
  position: fixed;
  top: 120px;
  left: 20px;
}

.browse-reference-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/layout/browse/BrowsePage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,eAAe;EACf,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".browse-container {\r\n  display: flex;\r\n  width: 100%;\r\n  padding: 80px;\r\n  overflow-y: auto;\r\n  background-color: white;\r\n}\r\n\r\n.browse-container::-webkit-scrollbar {\r\n  width: 6px;\r\n}\r\n\r\n.browse-container::-webkit-scrollbar-thumb {\r\n  background-color: #a6a5a5;\r\n  border-radius: 20px;\r\n}\r\n\r\n.browse-container::-webkit-scrollbar-track {\r\n  background-color: #f1f1f1;\r\n}\r\n\r\n.browse-container::-webkit-scrollbar-corner {\r\n  background-color: #f1f1f1;\r\n}\r\n\r\n.browse-container .switch-container {\r\n  position: fixed;\r\n  top: 120px;\r\n  left: 20px;\r\n}\r\n\r\n.browse-reference-button {\r\n  background: none;\r\n  border: none;\r\n  padding: 0;\r\n  cursor: pointer;\r\n  width: 18px;\r\n  height: 18px;\r\n  border-radius: 50%;\r\n  margin-left: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
